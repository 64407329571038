<template>
  <div class="my-10">
    <div class="w-full">
      <div class="flex items-center">
        <h1 class="w-2/5">Branch Name</h1>
        <h1 class="w-2/5">Email</h1>
        <h1 class="w-1/5">Phone Number</h1>
        <h1 class="w-1/5">Branch Code</h1>
        <h1 class="w-1/5"></h1>
      </div>
      <div
        class="flex items-center bg-white py-3 px-5 rounded-md my-4 hover:shadow-md cursor-pointer"
        v-for="branch in branches"
        :key="branch.id"
      >
        <div class="w-2/5 flex items-center gap-3">
          {{ branch.branch_name }}
        </div>
        <div class="w-2/5">
          {{ branch.branch_email }}
        </div>
        <div class="w-1/5">
          {{ branch.branch_phone }}
        </div>
        <div
          class="w-1/5 bg-red-300 bg-opacity-30 text-red-500 py-2 px-5 text-xs rounded-full truncate text-ellipse"
          :title="branch.branch_id"
        >
          {{ branch.branch_id }}
        </div>
        <div class="w-1/5 relative menu">
          <div class="flex ml-10">
            <div
              class="p-1 mr-1 rounded-full bg-gray-400"
              v-for="i in 3"
              :key="i"
            />
          </div>
          <div
            class="hidden z-10 absolute top-2 left-10 right-0 bg-white p-3 w-40 shadow-md rounded-lg"
          >
            <div
              class="bg-blue-300 bg-opacity-10 text-blue-400 font-semibold p-2 rounded-lg flex items-center"
              @click="$emit('edit', branch)"
            >
              <edit-icon class="mr-3" />
              View
            </div>
            <div
              class="bg-gray-300 bg-opacity-10 text-gray-400 font-semibold p-2 rounded-lg flex items-center mt-3"
              @click="$emit('revoke', branch)"
            >
              <edit-icon class="mr-3" />
              Edit
            </div>
            <div
              class="bg-red-300 bg-opacity-10 text-red-400 font-semibold p-2 rounded-lg flex items-center my-2"
              @click="$emit('delete', branch)"
            >
              <delete-icon class="mr-3" />
              Delete
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end gap-3">
      <template v-for="page in Math.ceil(total / perPage)">
        <div
          class="bg-white py-2 px-4 cursor-pointer rounded-md"
          :key="page"
          :class="
            page === currentPage
              ? 'bg-primary cursor-default text-white shadow-md'
              : ''
          "
          @click="$emit('fetchPage', page)"
        >
          {{ page }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "BranchTable",
  components: {
    EditIcon: () => import("@/assets/icons/EditIcon.vue"),
    DeleteIcon: () => import("@/assets/icons/DeleteIcon.vue"),
  },
  props: {
    allBranches: {
      required: true,
      type: Array,
    },
    perPage: {
      required: true,
      type: Number,
    },
    total: {
      required: true,
      type: Number,
    },
    currentPage: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      pageNumber: 0,
    };
  },
  computed: {
    branches() {
      return this.allBranches;
    },
  },
};
</script>

<style>
.menu:hover .hidden {
  display: block;
}
</style>
